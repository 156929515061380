// src/components/Dashboard.js
import AWS from 'aws-sdk';
import { useState, useEffect, useCallback } from 'react';
import { Col, Row, Container, Button, ProgressBar, Modal, Form, Breadcrumb } from 'react-bootstrap';
import { FaThLarge, FaList } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import { FaTrash } from 'react-icons/fa';


function Dashboard({ userRole, allowedFolders }) {
  console.log('User Role:', userRole);
  console.log('Allowed Folders:', allowedFolders);
  const [files, setFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile,setSelectedFile] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [viewMode, setViewMode] = useState('list'); // 'grid' or 'list'
  const [activeViewMode, setActiveViewMode] = useState('list'); // 追蹤'grid' or 'list'
  const [uploadOperation, setUploadOperation] = useState(null); //用於跟踪上傳操作
  const [currentFolder, setCurrentFolder] = useState('');

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = process.env.REACT_APP_S3_REGION;
  const ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  AWS.config.update({
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  
  // useEffect(() => {
  //   if (userRole === 'admin') {
  //     fetchFiles(currentPath);
  //   } else {
  //     // 以allowedFolders為前綴的currentPath才進行fetch
  //     if (allowedFolders.some(folder => currentPath.startsWith(folder))) {
  //       fetchFiles(currentPath);
  //     } else {
  //       setFiles([]);
  //     }
  //   }
  // }, [currentPath, userRole, allowedFolders]);

  useEffect(() => {
    fetchFiles(currentPath);
  }, [currentPath]);

  const debounce = (func, wait) => { // 防抖函數
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
 
  const fetchFiles = async (path) => {
    try {
      const normalizedPath = path ? `${path.replace(/\/+$/, '')}/` : '';
      const params = {
        Bucket: S3_BUCKET,
        Delimiter: '/',
        Prefix: normalizedPath,
      };
  
      const data = await s3.listObjectsV2(params).promise();
      let allItems = [...(data.CommonPrefixes || []), ...data.Contents];
    
      // 过滤文件和文件夹
      // if (userRole !== 'admin') {
      //   console.log('Filtering for non-admin user');
      //   console.log('allowedFolders:', allowedFolders);
      if (userRole !== 'admin') {
        allItems = allItems.filter(item => {
          const itemPath = item.Prefix || item.Key;
          // 检查当前路径是否是允许的文件夹的子路径
          return allowedFolders.some(folder => {
            if (folder === '*') return true;  // admin 可以访问所有文件夹
            const normalizedFolder = folder.replace(/\/+$/, '');
            return normalizedPath.startsWith(normalizedFolder) || itemPath.startsWith(normalizedFolder);
          });
        });
      }  
      if (!Array.isArray(allowedFolders)) {
        console.error('allowedFolders is not an array:', allowedFolders);
        // 如果 allowedFolders 不是数组，可以尝试将其转换为数组
        allowedFolders = allowedFolders ? [allowedFolders] : [];
      }

    
      const folders = allItems
        .filter(item => item.Prefix || item.Key.endsWith('/'))
        .map((item) => {
          const itemPath = item.Prefix || item.Key;
          return {
            Key: itemPath,
            Name: itemPath.replace(normalizedPath, '').replace(/\/+$/, ''),
            Type: 'folder',
          };
        })
        .filter(folder => folder.Name !== ''); // 过滤掉空名称的文件夹
    
      const files = allItems
        .filter(item => !item.Prefix && !item.Key.endsWith('/'))
        .map((item) => ({
          Key: item.Key,
          Name: item.Key.split('/').pop(),
          Type: getFileType(item.Key),
          Thumbnail: getThumbnailUrl(item.Key),
          LastModified: item.LastModified,
          Size: item.Size,
        }));
    
      setFiles([...folders, ...files]);
    }catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const getFileType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    if (['mp4', 'avi', 'mov'].includes(extension)) return 'video';
    if (['mp3', 'wav', 'ogg'].includes(extension)) return 'audio';
    return 'file';
  };

  const getThumbnailUrl = (key) => {
    const fileType = getFileType(key);
    if (fileType === 'image') {
      return s3.getSignedUrl('getObject', {
        Bucket: S3_BUCKET,
        Key: key,
        Expires: 60 * 5 // URL expires in 5 minutes
      });
    }
    if (fileType === 'video') return 'video.png';
    if (fileType === 'audio') return 'audio.png';
    return 'file.png';
  };
    //   清除上傳狀態
  const setCurrentPathAndClearStatus = (newPath) => {
    setCurrentPath(newPath);
    setUploadStatus('');
  };
  // 使用 debounce 包裝 handleFolderClick 函數
  const handleFolderClick = useCallback(
    debounce((folderName) => {
      const newPath = currentPath ? `${currentPath}/${folderName}` : folderName;
      setCurrentPathAndClearStatus(newPath);
    }, 300),
    [currentPath, setCurrentPathAndClearStatus]
  );

  const handleBackClick = () => {
    const newPath = currentPath.split('/').slice(0, -1).join('/');
    setCurrentPathAndClearStatus(newPath);
  };
  
  const handleDownload = async (key) => {
    try {
      const params = {
        Bucket: S3_BUCKET,
        Key: key,
      };

      const { Body, ContentType } = await s3.getObject(params).promise();
      const blob = new Blob([Body], { type: ContentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = key.split('/').pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleDelete = (key, type) => {
    setItemToDelete({ key, type });
    setShowDeleteConfirm(true);
  };

  const toggleUploadInterface = () => {
    setShowUpload(!showUpload);
    if (showUpload) {
      // 重置上傳相關狀態
      setSelectedFile(null);
      setProgress(0);
      setUploadStatus('');
    }
  };
  // 在組件中添加點擊事件處理
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showUpload && !event.target.closest('.upload-section')) {
        toggleUploadInterface();
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showUpload, toggleUploadInterface]);


  const confirmDelete = async () => {
    if (!itemToDelete) return;

    try {
        if (itemToDelete.type === 'folder') {
            // 確保資料夾路徑以 '/' 結尾 不以檔案數量判斷因有系統或隱藏檔案
            const folderPath = itemToDelete.key.endsWith('/') ? itemToDelete.key : `${itemToDelete.key}/`;
            
            const listParams = {
              Bucket: S3_BUCKET,
              Prefix: folderPath,
              MaxKeys: 1000  // 增加這個值以確保能列出所有物件
            };

        const listedObjects = await s3.listObjectsV2(listParams).promise();
        if (listedObjects.Contents.length > 1 || (listedObjects.Contents.length === 1 && listedObjects.Contents[0].Key !== folderPath)) {
            alert('資料夾內無檔案，才可刪除');
            setShowDeleteConfirm(false);
            return;
        }
        // 刪除資料夾標記（如果存在）
        const deleteParams = {
          Bucket: S3_BUCKET,
          Delete: { Objects: [{ Key: folderPath }] }
        };

        // listedObjects.Contents.forEach(({ Key }) => {
        //   deleteParams.Delete.Objects.push({ Key });
        // });

        await s3.deleteObjects(deleteParams).promise();
      } else {
        const params = {
          Bucket: S3_BUCKET,
          Key: itemToDelete.key,
        };
        await s3.deleteObject(params).promise();
      }

      setShowDeleteConfirm(false);
      fetchFiles(currentPath);
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('刪除操作失敗');
    }
  };
  const [selectedFiles, setSelectedFiles] = useState([]);  
  
  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };
  
  const handleUpload = async () => {
    if (selectedFiles.length === 0) return;
  
    for (const file of selectedFiles) {
      const uploadKey = currentPath ? `${currentPath}/${file.name}` : file.name;
  
      try {
        const params = {
          Bucket: S3_BUCKET,
          Key: uploadKey,
          Body: file,
          ContentType: file.type,
        };
  
        const upload = s3.upload(params);
        setUploadOperation(upload);
  
        upload.on('httpUploadProgress', (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        });
  
        await upload.promise();
        setUploadStatus(`${file.name} 上傳成功`);
      } catch (error) {
        if (error.code === 'RequestAbortedError') {
          setUploadStatus(`${file.name} 上傳已取消`);
          break;
        } else {
          setUploadStatus(`${file.name} 上傳失敗`);
          console.error('Upload Error:', error);
        }
      } finally {
        setUploadOperation(null);
      }
    }
  
    setProgress(0);
    fetchFiles(currentPath);
    setSelectedFiles([]);
  };
  
  const cancelUpload = () => {
    if (uploadOperation) {
      uploadOperation.abort();
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) return;

    try {
      const folderKey = currentPath ? `${currentPath}/${newFolderName}/` : `${newFolderName}/`;
      const params = {
        Bucket: S3_BUCKET,
        Key: folderKey,
        Body: '' // Empty body for folder
      };

      await s3.putObject(params).promise();
      setShowCreateFolder(false);
      setNewFolderName('');
      fetchFiles(currentPath);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };
  console.log('Allowed Folders:', allowedFolders);
  console.log('Current Path:', currentPath);
    //   顯示面包屑
  const renderBreadcrumb = () => {
        const paths = ['dashboard', ...currentPath.split('/').filter(p => p)];
        return (
          <Breadcrumb>
            {paths.map((path, index) => (
              <Breadcrumb.Item 
                key={index} 
                onClick={() => index === 0 ? setCurrentPathAndClearStatus('') : setCurrentPathAndClearStatus(paths.slice(1, index + 1).join('/'))}
                active={index === paths.length - 1}
              >
                {path}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        );
      };
  const toggleViewMode = (mode) => {
      setViewMode(mode);
      setActiveViewMode(mode);
  };
    //  顯示文件列表
  const renderFileList = () => {
    const fileItems = files.filter(item => item.Type !== 'folder');
    if (viewMode === 'grid') {
      return (
        <div className="file-grid">
          {fileItems.map((item) => (
            <div key={item.Key} className="file-item">
              <img src={item.Thumbnail} alt={item.Name} className="file-thumbnail" />
              <div className="file-name">{item.Name}</div>
              <div className="file-actions">
                <Button size="sm" onClick={() => handleDownload(item.Key)}>下載</Button>
                <Button size="sm" variant="danger" onClick={() => handleDelete(item.Key, 'file')}>刪除</Button>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <table className="file-list">
          <thead>
            <tr>
              <th>名稱</th>              
              <th>上次修改時間</th>
              <th>檔案大小</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {fileItems.map((item) => (
              <tr key={item.Key}>
                <td>{item.Name}</td>                
                <td>{new Date(item.LastModified).toLocaleString()}</td>
                <td>{item.Size} bytes</td>
                <td>
                  <Button size="sm" onClick={() => handleDownload(item.Key)}>下載</Button>
                  <Button size="sm" variant="danger" onClick={() => handleDelete(item.Key, 'file')}>刪除</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

    //管理文件夾   
    return (
      <Container fluid className="dashboard">
        <Row>
          <Col md={3} className="sidebar">
            <h2>工作區</h2>
            <Button variant="success" onClick={() => setShowCreateFolder(true)}>新建資料夾</Button>
            {files.filter(item => item.Type === 'folder').map((folder) => (
              <div key={folder.Key} className="folder-item">
                <div 
                  className={`folder ${currentPath === folder.Name ? 'active' : ''}`} 
                  onClick={() => handleFolderClick(folder.Name)}
                >
                  📁 {folder.Name}
                </div>
                {(userRole === 'admin' || allowedFolders.includes(folder.Name)) && (
                  <Button 
                    variant="link" 
                    className="delete-folder-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(folder.Key, 'folder');
                    }}
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
            ))}
          </Col>
        <Col md={9} className="main-content">
            {renderBreadcrumb()}
            <div className="top-actions">
                <div className="left-actions">
                    {currentPath && <Button variant="secondary" onClick={handleBackClick}>返回上一層</Button>}
                </div>
                <div className="right-actions">                  
                    <Button variant="outline-primary" onClick={() => toggleViewMode('grid')}
                      className={activeViewMode === 'grid' ? 'active' : ''}>  <FaThLarge /></Button>
                    <Button variant="outline-primary" onClick={() => toggleViewMode('list')}
                      className={activeViewMode === 'list' ? 'active' : ''}>  <FaList /></Button>
                    <Button variant="primary" onClick={() => setShowUpload(!showUpload)}>上傳檔案</Button>
                </div>
            </div>
          {renderFileList()}
          <div className="upload-section">
            {showUpload && (
              <div className="upload-form">
                <input type="file" multiple onChange={handleFileChange} />
                <Button onClick={handleUpload}>上傳</Button>
                <Button variant="secondary" onClick={cancelUpload} disabled={!uploadOperation}>取消上傳</Button>
                {progress > 0 && (
                  <div className="progress-container">
                    <div className="progress-info">
                      <span>{selectedFiles.length > 0 ? selectedFiles[0].name : ''}</span>
                      <ProgressBar now={progress} label={`${progress}%`} animated striped />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>{uploadStatus}</div>
          </div>
        </Col>
      </Row>

      <Modal show={showDeleteConfirm} onHide={() => setShowDeleteConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>確認刪除</Modal.Title>
        </Modal.Header>
        <Modal.Body>確定要刪除 {itemToDelete?.key} 嗎？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>
            取消
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            刪除
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateFolder} onHide={() => setShowCreateFolder(false)}>
        <Modal.Header closeButton>
          <Modal.Title>新建資料夾</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="輸入資料夾名稱"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateFolder(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleCreateFolder}>
            創建
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Dashboard;