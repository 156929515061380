import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import userPool from '../cognitoConfig';
import './Login.css';

const Login = ({ onLogin }) => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState(''); // 大名(別名)欄位
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const navigate = useNavigate();

  const passwordRequirements = [
    { regex: /.{6,}/, message: "密碼長度至少6字" },
    { regex: /[0-9]/, message: "密碼至少包含1個數字" },
    { regex: /[A-Z]/, message: "密碼至少包含1個大寫字母" },
    { regex: /[a-z]/, message: "密碼至少包含1個小寫字母" },
  ];

  const validatePassword = (password) => {
    for (const req of passwordRequirements) {
      if (!req.regex.test(password)) {
        return req.message;
      }
    }
    return null;
  };

const getUserAttributes = (cognitoUser) => {
  return new Promise((resolve, reject) => {
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        console.error('Error getting user attributes:', err);
        reject(err);
        return;
      }
      const attributes = {};
      result.forEach(attribute => {
        attributes[attribute.Name] = attribute.Value;
      });
      console.log('All user attributes:', attributes);
        resolve(attributes);
    });
  });
};

  const handleLogin = (e) => {
    e.preventDefault();
  
    const authenticationDetails = new AuthenticationDetails({
      Username: account,
      Password: password,
    });
  
    const user = new CognitoUser({
      Username: account,
      Pool: userPool
    });
  
    setCognitoUser(user);
  
    user.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        try {
          const userAttributes = await getUserAttributes(user);
          console.log('User attributes:', userAttributes);
          
          let allowedFolders = [];
          if (userAttributes['custom:role'] === 'admin') {
            // 对于 admin 用户，可以允许访问所有文件夹
            allowedFolders = ['*'];  // 使用 '*' 表示所有文件夹
          } else {
            // 对于非 admin 用户，使用 custom:allowedFolders 属性
            allowedFolders = userAttributes['custom:allowedFolders'] ? userAttributes['custom:allowedFolders'].split(',') : [];
          }
      
          onLogin(userAttributes['custom:role'], allowedFolders);
          navigate('/dashboard');
        // 移除下面的註解以啟用custom:approved屬性 審核功能
        // try {
        //   const userAttributes = await getUserAttributes(user);
        //   console.log('User attributes:', userAttributes);
        //   
        //   if (userAttributes['custom:approved'] === 'false') {
        //     setError('您的帳號尚未被管理員確認,請稍後再試。');
        //   } else {
        //     onLogin(userAttributes['custom:role'], userAttributes['custom:allowedFolders']);
        //     onLogin(userAttributes['custom:role'], userAttributes['custom:allowedFolders'].split(','));
        //   navigate('/dashboard');
        //   }
        } catch (error) {
          console.error('Error getting user attributes:', error);
          setError('獲取用戶屬性時發生錯誤：' + error.message);
        }
      },
      onFailure: (err) => {
        console.error('Login error:', err);
        if (err.code === 'UserNotConfirmedException') {
          setError('用戶未確認。請聯繫管理員確認您的帳戶。');
        } else {
          setError('登錄失敗：' + err.message);
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;
        setShowNewPassword(true);
      }
    });
  };

  const handleNewPassword = (e) => {
    e.preventDefault();
    if (!cognitoUser) {
      setError('發生錯誤，請重新登錄');
      return;
    }

    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: async (result) => {
        try {
          const userAttributes = await getUserAttributes(cognitoUser);
          console.log('User attributes:', userAttributes);
          onLogin(userAttributes['custom:role'], userAttributes['custom:allowedFolders']);
          navigate('/dashboard');
        } catch (error) {
          console.error('Error getting user attributes:', error);
          onLogin('limited', []);
          navigate('/dashboard');
        }
      },
      onFailure: (err) => {
        setError(err.message || JSON.stringify(err));
      }
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    
    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      return;
    }
  
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'custom:role',
        Value: 'limited',
      }),
      new CognitoUserAttribute({
        Name: 'custom:allowedFolders',
        Value: 'Share',
      }),
      new CognitoUserAttribute({
        Name: 'custom:nickname',
        Value: nickname,
      }),
      // new CognitoUserAttribute({
      //   Name: 'custom:approved',
      //   Value: 'false', // 新用戶默認未批准
      // }),
    ];
  
    userPool.signUp(account, password, attributeList, null, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
  
      // 註冊成功後的處理
      setError('註冊成功！請等待管理員審核並啟用您的帳戶。');
      setIsRegistering(false);
    });
  };

  return (
    <div className="login">
      <div className="login-form">
        <h2>{isRegistering ? '註冊' : '登入'}</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {!showNewPassword ? (
          <form onSubmit={isRegistering ? handleRegister : handleLogin}>
            <label>帳號:</label>
            <input
              type="text"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              required
              autoComplete="username"
            />
            {isRegistering && (
              <>
                <label>別名:</label>
                <input
                  type="text"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  required
                />
              </>
            )}
            <label>密碼:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />   
            <div className="button-container">
              <button type="submit">{isRegistering ? '註冊' : '登入'}</button>
              <button
                type="button"
                onClick={() => setIsRegistering(!isRegistering)}
              >
                {isRegistering ? '返回登入' : '註冊'}
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleNewPassword}>
            <label>新密碼:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
            <button type="submit">設定新密碼</button>
          </form>
        )}
      </div>
      {isRegistering && (
        <div className="password-requirements">
          <h3>帳密要求:</h3>
          <ul>
            <li>帳號: 隨意，可輸入英文、中文、數字。</li>
            <li>別名: 讓管理員辨識之名稱，可與帳號相同。</li>
            <li>密碼:
              <ul>
                <li>長度至少6位</li>
                <li>至少1個數字</li>
                <li>至少1個大寫字母</li>
                <li>至少1個小寫字母</li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
export default Login;
