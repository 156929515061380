import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <div className="home-content">
        <h1>檔案儲存共享</h1>
        <p>查看檔案與管理請登入。若未有帳號，請先註冊。</p>
        <div className="home-buttons">
          <Link to="/login" className="home-button-link"><button className="home-button">登入 \ 註冊</button></Link>
          <Link to="/introduction" className="home-button-link"><button className="home-button">介紹</button></Link>
        </div>
        {/* <Link to="/downloadarea" className="home-button-link full-width"><button className="home-button">資料檔案</button></Link> */}
      </div>
      <div className="home-image">
        <img src=" folder.png" alt="一個文件資料夾" />
      </div>
    </div>
  );
};

export default Home;
