// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
// import DownloadArea from './components/DownloadArea';
import ManageUsers from './components/ManageUsers';
import Introduction from './components/Introduction';
import Da_introduction from './components/Da_introduction';

import './App.css';

// 新增這個組件來處理標題更新
function TitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    const baseTitle = "耶穌是救主"; // 您的基本標題
    let pageTitle = "";

    switch(location.pathname) {
      // case '/':
      //   pageTitle = "首頁";
      //   break;
      case '/login':
        pageTitle = "登入";
        break;
      case '/dashboard':
        pageTitle = "儀表板";
        break;
      // case '/downloadarea':
      //   pageTitle = "資料集";
      //   break;
      case '/introduction':
        pageTitle = "使用方法";
        break;
      case '/da_introduction':
        pageTitle = "上傳使用方法";
        break;
      default:
        pageTitle = "";
    }

    document.title = pageTitle ? `${baseTitle} - ${pageTitle}` : baseTitle;
  }, [location]);

  return null;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [allowedFolders, setAllowedFolders] = useState([]);

  const handleLogin = (role, folders) => {
    setIsLoggedIn(true);
    setUserRole(role);
    setAllowedFolders(folders);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserRole('');
    setAllowedFolders([]);
  };

  return (
    <Router>
      <TitleUpdater />
      <div className="App">
        <header className="App-header">
          <div className="header-left">
            <img src="logo192.png" alt="Logo" className="logo" />
          </div>
          <nav className="main-nav">
            {isLoggedIn ? (
              <>
                <NavLink to="/dashboard" className="nav-link" activeClassName="active">Dashboard</NavLink>
                {userRole === 'admin' ? (
                  <NavLink to="/manageusers" className="nav-link" activeClassName="active">管理使用者</NavLink>
                ) : null}
                <NavLink to="/da_introduction" className="nav-link" activeClassName="active">上傳使用方法</NavLink>
              </>
            ) : (
              <>
                <NavLink to="/" className="nav-link" activeClassName="active">首頁</NavLink>
                <NavLink to="/introduction" className="nav-link" activeClassName="active">使用方法</NavLink>
              </>
            )}
          </nav>
          <div className="header-right">            
            {isLoggedIn ? (
              <button className="auth-link-button" onClick={handleLogout}>Logout</button>
            ) : (
              <NavLink to="/login" className="auth-link-button">Login</NavLink>
            )}
          </div>
        </header>
        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route
              path="/dashboard"
              element={isLoggedIn ? <Dashboard userRole={userRole} allowedFolders={allowedFolders} /> : <Navigate to="/login" />}
              
            />
            <Route
              path="/manageusers"
              element={isLoggedIn && userRole === 'admin' ? <ManageUsers /> : <Navigate to="/dashboard" />}
            />
            {/* <Route path="/downloadarea" element={<DownloadArea />} /> */}
            <Route path="/introduction" element={<Introduction />} />
            <Route
              path="/da_introduction"
              element={isLoggedIn ? <Da_introduction /> : <Navigate to="/login" />}
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;