import React, { useEffect, useState } from 'react';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import userPool from '../cognitoConfig';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function ManageUsers() {
  AWS.config.update({
    region: userPool.getUserPoolId().split('_')[0],
  });

  const [pendingUsers, setPendingUsers] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [s3Folders, setS3Folders] = useState([]);
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);

  const cognitoServiceProvider = new AWS.CognitoIdentityServiceProvider();
  const s3 = new AWS.S3();

  useEffect(() => {
    fetchUsers();
    fetchS3Folders();
  }, []);

  //用 username 作為次要排序標準，因為它是唯一且不會改變的。
  useEffect(() => {
    const sorted = [...registeredUsers].sort((a, b) => {
      if (a['custom:role'] === 'admin' && b['custom:role'] !== 'admin') return -1;
      if (a['custom:role'] !== 'admin' && b['custom:role'] === 'admin') return 1;
      return a.username.localeCompare(b.username); // 使用 username 作為次要排序標準
    });
    setSortedUsers(sorted);
  }, [registeredUsers]);

  const fetchS3Folders = () => {
    const params = {
      Bucket: 'jesusissavior-file', // 替換為你的S3 bucket名稱
      Delimiter: '/',
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error('Error fetching S3 folders:', err);
        return;
      }

      const folders = data.CommonPrefixes.map((prefix) => prefix.Prefix.replace('/', ''));
      setS3Folders(folders);
    });
  };

  const fetchUsers = () => {
    const params = {
      UserPoolId: userPool.getUserPoolId(),
      Limit: 30, // 增加限制以確保獲取所有用戶
    };

    cognitoServiceProvider.listUsers(params, (err, data) => {
      if (err) {
        console.error('Error fetching users:', err);
        return;
      }

      const pending = [];
      const registered = [];
      data.Users.forEach((user) => {
        const attributes = user.Attributes.reduce((acc, attr) => {
          acc[attr.Name] = attr.Value;
          return acc;
        }, {});

        if (attributes['custom:approved'] === 'false') {
          pending.push({ username: user.Username, ...attributes });
        } else {
          registered.push({ username: user.Username, ...attributes });
        }
      });

      setPendingUsers(pending);
      setRegisteredUsers(registered);
    });
  };

  const showRoleModal = (user) => {
    setSelectedUser(user);
    setNewRole(user['custom:role']);
    setIsRoleModalVisible(true);
  };

  const handleRoleOk = () => {
    updateUserRole(selectedUser.username, newRole);
    setIsRoleModalVisible(false);
  };

  const handleRoleCancel = () => {
    setIsRoleModalVisible(false);
  };

  const showFolderModal = (user) => {
    setSelectedUser(user);
    setSelectedFolders((user['custom:allowedFolders'] || '').split(',').filter(Boolean));
    setIsFolderModalVisible(true);
  };

  const handleFolderOk = () => {
    updateUserFolders(selectedUser.username, selectedFolders);
    setIsFolderModalVisible(false);
  };

  const handleFolderCancel = () => {
    setIsFolderModalVisible(false);
  };

  const confirmUserSignup = async (username) => {
    const params = {
      UserPoolId: userPool.getUserPoolId(),
      Username: username,
    };

    try {
      await cognitoServiceProvider.adminConfirmSignUp(params).promise();
      approveUser(username);
    } catch (error) {
      console.error('Error confirming user:', error);
    }
  };

  const approveUser = (username) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    const attributes = [
      new CognitoUserAttribute({
        Name: 'custom:approved',
        Value: 'true',
      }),
      new CognitoUserAttribute({
        Name: 'custom:role',
        Value: 'limited',
      }),
    ];

    cognitoUser.updateAttributes(attributes, (err, result) => {
      if (err) {
        console.error('Error updating user attributes:', err);
        return;
      }
      fetchUsers();
    });
  };

  const rejectUser = (username) => {
    const params = {
      UserPoolId: userPool.getUserPoolId(),
      Username: username,
    };

    cognitoServiceProvider.adminDeleteUser(params, (err, data) => {
      if (err) {
        console.error('Error deleting user:', err);
        return;
      }
      fetchUsers();
    });
  };

  const updateUserRole = (username, role) => {
    const params = {
      UserPoolId: userPool.getUserPoolId(),
      Username: username,
      UserAttributes: [
        {
          Name: 'custom:role',
          Value: role,
        },
      ],
    };

    cognitoServiceProvider.adminUpdateUserAttributes(params, (err, result) => {
      if (err) {
        console.error('Error updating user role:', err);
        return;
      }
      fetchUsers();
    });
  };

  const updateUserFolders = (username, selectedFolders) => {
    // 過濾掉不存在的資料夾
    const validFolders = selectedFolders.filter(folder => s3Folders.includes(folder));
    
    const params = {
      UserPoolId: userPool.getUserPoolId(),
      Username: username,
      UserAttributes: [
        {
          Name: 'custom:allowedFolders',
          Value: validFolders.join(','),
        },
      ],
    };

    cognitoServiceProvider.adminUpdateUserAttributes(params, (err, result) => {
      if (err) {
        console.error('Error updating user folders:', err);
        return;
      }
      // 更新本地狀態而不是重新獲取所有用戶
      setRegisteredUsers(prevUsers => 
        prevUsers.map(user => 
          user.username === username 
            ? { ...user, 'custom:allowedFolders': validFolders.join(',') }
            : user
        )
      );
    });
  };

  // 過濾並顯示有效的資料夾
  const getValidFolders = (foldersString) => {
    if (!foldersString) return '';
    const folders = foldersString.split(',');
    return folders.filter(folder => s3Folders.includes(folder)).join(', ');
  };

  const handleFolderCheckboxChange = (folder) => {
    setSelectedFolders((prevFolders) =>
      prevFolders.includes(folder)
        ? prevFolders.filter((f) => f !== folder)
        : [...prevFolders, folder]
    );
  };

  const columns = [
    { title: '名稱', dataIndex: 'custom:nickname', key: 'nickname' },
    { title: '角色', dataIndex: 'custom:role', key: 'role' },
    { title: '允許的資料夾', dataIndex: 'custom:allowedFolders', key: 'allowedFolders' },
    {
      title: '操作',
      key: 'action',
      render: (user) => (
        <>
          <Button variant="primary" size="sm" onClick={() => showRoleModal(user)}>修改角色</Button>
          <Button variant="secondary" size="sm" onClick={() => showFolderModal(user)}>管理資料夾</Button>
        </>
      ),
    },
  ];

  return (
    <div className="manage-users">
      <h3>管理使用者</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user) => (
            <tr key={user.username}>
              <td>{user['custom:nickname']} ({user.username})</td>
              <td>{user['custom:role']}</td>
              <td>{getValidFolders(user['custom:allowedFolders'])}</td>
              <td>
                <Button variant="primary" size="sm" onClick={() => showRoleModal(user)}>修改角色</Button>{' '}
                <Button variant="secondary" size="sm" onClick={() => showFolderModal(user)}>管理資料夾</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={isRoleModalVisible} onHide={() => setIsRoleModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>修改角色</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>確定要將 {selectedUser?.['custom:nickname']} 的角色修改為：</p>
          <Form.Select
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
          >
            <option value="limited">Limited</option>
            <option value="admin">Admin</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRoleCancel}>取消</Button>
          <Button variant="primary" onClick={handleRoleOk}>確定</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isFolderModalVisible} onHide={() => setIsFolderModalVisible(false)}>
        <Modal.Header closeButton>
          <Modal.Title>管理資料夾</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>選擇 {selectedUser?.['custom:nickname']} 可訪問的資料夾：</p>
          {s3Folders.map((folder) => (
            <Form.Check
              key={folder}
              type="checkbox"
              id={`folder-checkbox-${folder}`}
              label={folder}
              checked={selectedFolders.includes(folder)}
              onChange={() => handleFolderCheckboxChange(folder)}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFolderCancel}>取消</Button>
          <Button variant="primary" onClick={handleFolderOk}>確定</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ManageUsers;
