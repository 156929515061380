// Introduction.js
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Introduction.css';

const steps = [
  {
    title: "歡迎使用",
    image1: "/images/JIS_I1.PNG",
    description: "這是一個簡單的介紹，幫助你了解如何使用網站。<br>左側為'首頁'。",
    hasTwoImages: false
  },
  {
    title: "註冊",
    image1: "/images/r1.PNG",
    image2: "/images/Registering.PNG",
    description: "<br>◆點選首頁'登入 註冊'。<br>◆再點選'註冊'後。<br>◆按照「帳密要求」'填寫資料，點擊'註冊'，完成後須等待許可。",
    hasTwoImages: true
  },
  {
    title: "登入&資料呈現",
    image1: "/images/login.PNG",
    image2: "/images/download.PNG",
    description: "◆待通知帳號生效後，使用帳號密碼登入。<br>◆資料夾會呈現在左側工作區。<br>登入後，另有上傳、編輯教學",
    hasTwoImages: true
  },
  {
    title: "資料夾操作",
    image1: "/images/link.PNG",
    image2: "/images/downloadgrid.PNG",
    description: "<br>◆進資料夾後，可使用'返回上一層'到前一資料夾，或點選連結跳轉。<br>◆畫面右上方可以選擇資料的呈現方式，分別為'清單'與'縮圖'。",
    hasTwoImages: true
  },
  // 可以繼續添加更多步驟...
];

function Introduction() {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <Container className="introduction-container">
      <h1 className="introduction-title">使用方法</h1>
      {steps[currentStep].hasTwoImages ? (
        <Row>
          <Col md={6} className="image-container">
            <img 
              src={steps[currentStep].image1} 
              alt={`${steps[currentStep].title} - 圖片1`} 
              className="introduction-image"
            />
          </Col>
          <Col md={6} className="image-container">
            <img 
              src={steps[currentStep].image2} 
              alt={`${steps[currentStep].title} - 圖片2`} 
              className="introduction-image"
            />
          </Col>
          <Col md={6} className="introduction-content">
            <h2>{steps[currentStep].title}</h2>
            <p dangerouslySetInnerHTML={{ __html: steps[currentStep].description }}></p>
          </Col>
        </Row>
      ) : (
        <Row className="single-image-layout">
          <Col md={6} className="image-container">
            <img 
              src={steps[currentStep].image1} 
              alt={`${steps[currentStep].title} - 圖片1`} 
              className="introduction-image"
            />
          </Col>
          <Col md={6} className="introduction-content">
            <h2>{steps[currentStep].title}</h2>
            <p dangerouslySetInnerHTML={{ __html: steps[currentStep].description }}></p>
          </Col>
        </Row>
      )}
      <div className="introduction-navigation">
        <Button onClick={prevStep} disabled={currentStep === 0}>上一步</Button>
        <span>{currentStep + 1} / {steps.length}</span>
        <Button onClick={nextStep} disabled={currentStep === steps.length - 1}>下一步</Button>
      </div>
    </Container>
  );
}

export default Introduction;