import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Da_introduction.css';

const steps = [
  {
    title: "登入Login",
    image: "/images/login.PNG",
    description: "要進入此工作區必須登入。<br>首頁'登入'或畫面右上'Login'皆可以登入。<br>❗使用後，需登出。"
  },
  {
    title: "資料夾管理",
    image: "/images/floder.jpg",
    description: "畫面左側可以新建資料夾。<br>◆刪除資料夾，只需鼠標移動到該資料夾並點按垃圾桶。<br>❗注意：若資料夾內有檔案，則無法刪除資料夾。"
  },
  {
    title: "上傳檔案-1",
    image: "/images/upload-1.PNG",
    description: "點擊畫面右上'上傳檔案'按鈕，並'選擇檔案'上傳檔案。"
  },
  {
    title: "上傳檔案-2",
    image: "/images/upload-2.jpg",
    description: "◆可以使用「Ctrl鍵」多選檔案，也可以直接拖拉檔案到畫面中。<br>◆傳輸途中可以'取消上傳'。"
  },
  {
    title: "下載&刪除",
    image: "/images/delet.PNG",
    description: "上傳完成後的檔案，可以查看、下載或刪除。"
  },
  {
    title: "登出Logout",
    image: "/images/logout.PNG",
    description: "使用後，請記得登出，點選畫面右上'Logout'。"
  },
];

function Da_introduction() {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <Container className="da_introduction-container">
      <h1 className="da_introduction-title">使用方法</h1>
      <Row>
        <Col md={6}>
          <img 
            src={steps[currentStep].image} 
            alt={steps[currentStep].title} 
            className="da_introduction-image"
          />
        </Col>
        <Col md={6}>
          <div className="da_introduction-content">
            <h2>{steps[currentStep].title}</h2>
            <p dangerouslySetInnerHTML={{ __html: steps[currentStep].description }}></p>
          </div>
        </Col>
      </Row>
      <div className="da_introduction-navigation">
        <Button onClick={prevStep} disabled={currentStep === 0}>上一步</Button>
        <span>{currentStep + 1} / {steps.length}</span>
        <Button onClick={nextStep} disabled={currentStep === steps.length - 1}>下一步</Button>
      </div>
    </Container>
  );
}

export default Da_introduction;
