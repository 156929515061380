// 登入所使用的用戶池配置
import { CognitoUserPool } from 'amazon-cognito-identity-js';

// const poolData = {
//     UserPoolId: 'us-west-2_viNxMciji', // 替換為你的用戶池 ID
//     ClientId: '1mjmebr1hfcvhkh7u9oanbjn07' // 替換為你的應用客戶端 ID
// };
const poolData = {
    UserPoolId: 'us-west-2_yLGV9bwnr', // 替換為你的用戶池 ID
    ClientId: '243tni80cgfq6hpu16roju2005' // 替換為你的應用客戶端 ID
};


export default new CognitoUserPool(poolData);
